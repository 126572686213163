@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

* {
  box-sizing: border-box;
}

.about {
  /* position: relative; */
  width: 100%;
  padding: 45px 0;
}

.about .section-header {
  margin-bottom: 30px;
}

.about .about-img {
  height: 100%;
  padding: 10px;

  border-radius: 10px;
  /* overflow: hidden; */
}

.about .about-img1 {
  height: 100%;
  padding: 10px;
}

.align-items {
  flex-direction: row-reverse;
}

.about .about-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about .about-text p {
  font-size: 16px;
}

.about .about-text1 p {
  font-size: 16px;
}

.about .about-text a.btn {
  margin-top: 15px;
  padding: 15px 35px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #aa9166;
  border: 2px solid #aa9166;
  border-radius: 0;
  background: #121518;
  transition: 0.3s;
}

.about .about-text a.btn:hover {
  color: #121518;
  background: #aa9166;
}

@media (max-width: 767.98px) {
  .about .about-img {
    margin-bottom: 30px;
    height: auto;
  }
}

.col-md-8 {
  justify-content: flex-start;
}

.button {
  border-radius: 4px;
  background-color: #4c4543;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 28px;
  padding: 0px;
  width: 160px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.button:hover {
  color: #aa9166;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.3s;
}

.button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}
.relative {
  position: relative;
  z-index: 1;
}

/* navbar */

@media (max-width: 768px) {
  .itemss {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    flex-wrap: wrap;
  }
}
.navbar {
  background-color: #ffffff !important;
  padding: -10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  z-index: index auto;
  width: 100%;
}
.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  width: 100%;
  z-index: 1;
  background-color: #ffffff;
}

.footer {
  /* position: relative; */
  margin-top: 45px;
  padding-top: 50px;
  background: #121518;
  color: #ffffff;
}

.footer .footer-about,
.footer .footer-link,
.footer .footer-contact {
  /* position: relative; */
  margin-bottom: 45px;
  color: #999999;
}

.footer .footer-about h2,
.footer .footer-link h2,
.footer .footer-contact h2 {
  /* position: relative; */
  margin-bottom: 30px;
  font-size: 25px;
  font-weight: 600;
  color: #aa9166;
}

.footer .footer-link a {
  display: block;
  margin-bottom: 10px;
  color: #fff;
  transition: 0.3s;
}

.footer .footer-link a::before {
  position: relative;
  /* content: "\f105"; */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .footer-link a:hover {
  letter-spacing: 1px;
}

.footer .footer-contact p i {
  width: 25px;
}

.footer .footer-social {
  position: relative;
  margin-top: 20px;
}

.footer .footer-social a {
  display: inline-block;
}

.footer .footer-social a i {
  margin-right: 15px;
  font-size: 18px;
  color: #aa9166;
}

.footer .footer-social a:last-child i {
  margin: 0;
}

.footer .footer-social a:hover i {
  color: #999999;
}

.footer .footer-menu .f-menu {
  position: relative;
  padding: 15px 0;
  /* background: #000000; */
  font-size: 0;
  text-align: center;
  color: #3b3939;
}

.footer .footer-menu .f-menu a {
  /* color: #999999; */
  font-size: 16px;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.footer .footer-menu .f-menu a:hover {
  color: #aa9166;
}

.footer .footer-menu .f-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.footer .copyright {
  padding: 30px 15px;
}

.footer .copyright p {
  margin: 0;
  color: #999999;
}

.footer .copyright .col-md-6:last-child p {
  text-align: right;
}

.footer .copyright p a {
  color: #aa9166;
  font-weight: 500;
  letter-spacing: 1px;
}

.footer .copyright p a:hover {
  color: #ffffff;
}

@media (max-width: 768px) {
  .footer .copyright p,
  .footer .copyright .col-md-6:last-child p {
    margin: 5px 0;
    text-align: center;
  }
}
.colors {
}

.imag {
  border-radius: 32px;

  transition: 0.4s ease-in-out transform, 0.4s ease-in-out box-shadow;

  &:hover {
    box-shadow: 1px 1px 0 1px #f9f9fb, -1px 0 28px 0 rgba(34, 33, 81, 0.01),
      54px 54px 28px -10px rgba(34, 33, 81, 0.15);
  }
}

.imag1 {
  transform: rotate3d(0.5, -0.866, 0, 15deg) rotate(2deg);

  transition: transform 0.4s ease, box-shadow 0.4s ease;
  border-radius: 0.5em;

  &:hover {
    transform: rotate3d(0, 0, 0, 0deg) rotate(0deg);
  }
}
