
@media (max-width: 768px) {

    .classs{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;    
    }
  }

  .button {
    border-radius: 4px;
    background-color: #fff;
    border: none;
    color: #000;
   
    text-align: center;
    font-size: 28px;
    padding: 20px;
    width: 200px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
   
  }
  
  .button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
    text-decoration: underline;
  }
  
  .button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
    
  }
  
  .button:hover span {
    padding-right: 25px;
  }
  
  .button:hover span:after {
    opacity: 1;
    right: 0;
  }
  .Voides{
    padding: 50px;
    
  }
  video{
    height: 500px;
  }