body{
    margin: 0px;
  }
.city{
    background-color:lightblue;
    padding: 20px;
    width: 100%;
    font-size: 30px;
}
.container p{
    text-align: justify;
}
ul {
    list-style-type: disc; 
    margin-left: 2rem; 
   
  }
  li {
    margin-bottom: 1rem;
    margin-top: 1rem; 
  }
  .img2{
    margin-left: 13rem;
    margin-top: 10px;
  }
  .video{
    height: 50%;
    width: 100%;
  }
  .how{
    height: 200px;
    width: 100%;
  }
  @media screen and (max-width:992px){
    .img2{
      margin-left: 1rem;
      margin-top: 10px;
    }
    .flex p{
      text-align: center;
    }
  
    section{
      margin-bottom: -145px;
      margin-top: -100px;
    }
    .whatsapp{
      margin-left: 64px;
    }
    .date{
      margin-left: 50px;
    }
  }